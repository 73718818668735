<template>
  <div>
    <div class="pc template-market-container">
      <templates></templates>
    </div>
    <div class="mobile template-market-container">
      <templates-mobile></templates-mobile>
    </div>
  </div>
</template>

<script>
  import Templates from "./Market/Pc/Templates";
  import TemplatesMobile from "./Market/Mobile/Templates";
  export default {
    name: "TemplateMarket",
    components: {
      Templates,
      TemplatesMobile
    },
    created() {

    },
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>
  .pc.template-market-container
    padding 64px 0 80px 0
  .mobile.template-market-container
    padding 59px 0 80px 0
</style>
